import React from "react"
import { graphql } from "gatsby"
import get from 'lodash/get'
import Layout from "../../components/Layout"
import { GatsbySeo, ArticleJsonLd } from 'gatsby-plugin-next-seo';

class Page extends React.Component {
  slider() {
    var slideIndex = 1;
    showSlides(slideIndex);
    var prev = document.getElementById('prev');
    var next = document.getElementById('next');
    prev.onclick = function(){ showSlides(slideIndex += -1); };
    next.onclick = function(){ showSlides(slideIndex += 1); };
    function showSlides(n) {
      var i;
      var slides = document.getElementsByClassName("card");
      var dots = document.getElementsByClassName("dot");
      if (n > slides.length) {slideIndex = 1}    
      if (n < 1) {slideIndex = slides.length}
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";  
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[slideIndex-1].style.display = "block";  
      dots[slideIndex-1].className += " active";
    }
    // Swipe - touch devices
    var card = document.getElementsByClassName('content');
    for (var c = 0; c<card.length; c++) {
      var startx = 0
      card[c].addEventListener('touchstart', function(e){
        var touchobj = e.changedTouches[0]
        startx = parseInt(touchobj.clientX) 
      }, {passive: true})
      card[c].addEventListener('touchend', function(e){
        var touchobj = e.changedTouches[0]
        var dist = touchobj.clientX - startx
        if((startx > touchobj.clientX) && (dist < -50)) {
          showSlides(slideIndex += 1);
        }
        else if ((startx < touchobj.clientX) && (dist > 50) ) {
          showSlides(slideIndex += -1);
        }
      }, {passive: true})
    }
  }
  componentDidMount() {
    if(document.getElementById("slider") != null) {
      var cards = document.getElementById("slider").innerHTML;
      var new_html = `<a class="prev" id="prev" >&#10094;</a>${cards}<a class="next" id="next">&#10095;</a><div style="text-align:center" class="dots"><span class="dot"></span><span class="dot"></span><span class="dot"></span><span class="dot"></span><span class="dot"></span></div>`;
      document.getElementById("slider").innerHTML = new_html;
      this.slider();
    }
    //Href links scroller&list
    const kasino = get(this, 'props.data.allWpCptKasino.edges')
    const uri = kasino.map(({node}) => {
      var title = node.title;
      var nimi = document.getElementsByClassName('name');
      for(var t =0; t<nimi.length; t++) {
        if(title === nimi[t].innerHTML) { 
          nimi[t].parentElement.parentElement.nextElementSibling.children[1].href = `/kokemuksia/${node.slug}/`
        }
      }
      var linkki = document.querySelectorAll('.casinoRate .linkki');
      for(var v =0; v<linkki.length; v++) {
        var title2 = linkki[v].parentElement.previousElementSibling.children[1].children[0].children[0].innerHTML;
        if(node.title === title2) {
          linkki[v].href = `/kokemuksia/${node.slug}/`
        }
      }
    });
  }
  render() {
    const page = get(this, 'props.data.page')
    return (
      <Layout bodyClass={`page-template-default page page-id-${page.databaseId} wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}>
        <GatsbySeo 
          title={page.seo.title} description={page.seo.metaDesc} 
          canonical={`https://www.vetoveljet.com/${page.slug}/`}
        />
        				           <ArticleJsonLd
          url={`https://www.vetoveljet.com/${page.slug}/`}
          headline={`${page.seo.title}`}
          images="https://www.vetoveljet.com/images/vedonlyonti-logo.webp"
          authorName="Kalle Suikkonen"
          description={`${page.seo.metaDesc}`}
          publisherName="Vetoveljet"
          publisherLogo="https://www.vetoveljet.com/images/vedonlyonti-logo.webp"
        />
        <article className={`post-${page.databaseId} post page type-page status-publish hentry testi`} id={`post-${page.databaseId}`}>
          <header className="entry-header has-text-align-center header-footer-group">
            <div className="entry-header-inner section-inner medium">
              <h1 className="entry-title" dangerouslySetInnerHTML={{ __html: page.title }} />
            </div>
          </header>
          <div className="post-inner thin container">
            <div className="entry-content" dangerouslySetInnerHTML={{ __html: page.content }}/>
          </div>
        </article>
      </Layout>
    )
  }
}
export default Page
export const query = graphql`
  query page($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPage(id: { eq: $id }) {
      ...PageContent
      seo {
        title
        metaDesc
      }
      slug
    }
    allWpCptKasino {
      edges {
        node {
          id
          uri
          slug
          title
        }
      }
    }
    nextPage: wpPage(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPage(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`
